const reducer = (state, action) => {
    switch (action.type) {
        case 'SET_LANGUAGE':
            return {
                ...state,
                language: action.payload,
                strings: {
                    ...state.localization["english"],
                    ...state.localization[action.payload]
                }
            }
        default:
            return state
    }
}

export default reducer