export default {
    english: {
        summary_error: "Summary Error",
        failed_to_fetch_news: "Failed to fetch the news.",
        features: "Features",
        for_developers: "For Developers",
        assets: "Assets",
        news: "News",
        join_us: "Join Us",
        team: "Team",
        teamManagement: "Management",
        teamDev: "Dev Team",
        teamQA: "QA Team",
        support: "Support",

        build_title: "Create Your Own Branded DEX",
        build_description: "AtomicDEX is just the first end-user application built on top of Komodo DeFi Engine - an open-source atomic swap protocol complete with a robust backend, DEX feature set, and APIs. Use this technology to build wallets, DEXs, and other powerful financial applications.",
        shared_liquidity_pool: "Shared Liquidity Pool",
        shared_liquidity_pool_description: "All products using the AtomicAPI framework share a unified liquidity pool.",
        mobile_native: "Multi-Platform",
        mobile_native_description: "Atomic swaps are supported by desktop and mobile devices through GUI applications as well as the command line.",
        liquidity_multiplier: "Liquidity Multiplier",
        liquidity_multiplier_description: "Place any number of limit orders with the same funds at any number of price points.",
        multi_swap_support: "Multi-Swap Support",
        multi_swap_support_description: "Multiple swaps can be done simultaneously on a single device.",
        read_dev_docs: "Read the developer docs",
        read_atomic_swaps: "More about atomic swaps",

        compatible_title: "Compatible With 99% of Crypto Assets",
        compatible_description: "Under the hood, 99 percent of crypto assets, including Bitcoin, all UTXO-based coins, Ether and ERC-20 tokens, are compatible with AtomicDEX. We are adding support for new assets with each release.",
        featured: "Featured Coins and Tokens",
        eth_erc: "ETH and ERC-20",
        qtum_qrc: "QTUM and QRC-20",
        utxo_coins: "UTXO Coins",
        bnb_bep: "BNB and BEP-20",

        swaps_total: "Swaps Total",
        swaps_per_day: "Swaps last 24h",
        swaps_per_month: "Swaps last 30d",
        more_stats: "More Stats",

        doing_right_title: "How AtomicDEX Is Different",
        using_p2p_orderbooks: "Decentralized P2P Order Books",
        using_p2p_orderbooks_description: "Many DEXs in the blockchain space today aren’t genuinely non-custodial since user funds placed in liquidity pools are constantly exposed to impermanent loss, rugpulls, and vampire attacks. AtomicDEX, in contrast, avoids these issues by using decentralized P2P order books.",
        cross_chain_support: "Widest Cross-Protocol Support",
        cross_chain_support_description: "AtomicDEX doesn’t limit trading to a single blockchain protocol. AtomicDEX offers the widest cross-protocol support in the entire blockchain space. It’s easy to trade BTC, ETH, all ERC-20 tokens, KMD, and hundreds of other digital assets natively from dozens of blockchains — no proxy tokens required.",
        secure_as_possible: "Secure Trading Platform",
        secure_as_possible_description: "Atomic swaps are designed such that both traders either receive the funds they desire, or nothing happens at all and both traders retain the funds they started with.",

        header_title: "Upgrade Your DEXperience",
        header_description: "AtomicDEX is a secure wallet and non-custodial decentralized exchange rolled into one application.",
        header_download: "Try AtomicDEX Beta on any platform and start making atomic swaps today.",

        read_more: "Read more",
        latest_news: "Latest news",
        news_error: "News Error",
        no_news: "There is not any news",

        lets_try: "Try it",
        lets_try_download: "Hold your crypto and start making atomic swaps today.",

        security_title: "New Level of Security & Decentralization",
        security_subtitle: "Peer-to-Peer Trading, No Middleman",
        private_keys: "Your keys, your coins",
        start_trading: "Start trading immediately, no deposit or withdrawal wait times",
        trading_pairs: "Unrestricted trading pairs, swap all assets directly",
        low_fees: "No trading fee for makers, takers only pay 0.13%",

        partner_with_us: "JOIN THE DEX NETWORK",
        partner_with_us_description: "Embrace DeFi 2.0, and adopt atomic swap technology. Create your own DEX, become a liquidity provider, or list your asset.",
        join: "Join",
        your_name: "Your Name",
        your_email: "Your Email",
        your_message: "Your Message",
        project_website: "Project Website",
        send: "Send",
        resend: "Try again",
        cancel: "Cancel",
        close: "Close",
        form_error: "Something went wrong",
        form_try_again: "Please try again later",
        form_success: "Thank you! Your message was successfully sent. We'll get back to you as soon as possible.",
        click_consent: "By clicking on the send button, you consent to the processing of your personal data",

        komodo_platform: "Komodo Platform",
        atomicdex: "AtomicDEX",

        twitter: "Twitter",
        telegram: "Telegram",
        discord: "Discord",

        copyright: "© 2021 Product by",
        design_by: "Design by Weekend Studio",

        language: "Language",
        english: "English",
        chinese: "体中文",
        korean: "한국어",
        japanese: "日本語",
        russian: "Русский"
    },
    russian: {
        summary_error: "Ошибка",
        failed_to_fetch_news: "Не удалось загрузить новости.",
        features: "Функции",
        for_developers: "Для Разработчиков",
        assets: "Ассеты",
        news: "Новости",
        join_us: "Присоединяйтесь к нам",
        team: "Команда",
        teamManagement: "Management",
        teamDev: "Dev Team",
        teamQA: "QA Team",
        support: "Поддержка",

        build_title: "Создайте свою собственную брендированную DEX",
        build_description: "AtomicDEX - это первое приложение, построенное на основе Komodo DeFi Engine - протокола атомарного свопа с открытым исходным кодом, надежным бэкэндом, набором функций DEX и API. Используйте эту технологию для создания кошельков, DEX и других мощных финансовых приложений.",
        shared_liquidity_pool: "Общий пул ликвидности",
        shared_liquidity_pool_description: "Все продукты, использующие структуру AtomicAPI, имеют общий пул ликвидности.",
        mobile_native: "Мультиплатформенность",
        mobile_native_description: "Атомарные свопы поддерживаются десктопными и мобильными устройствами через приложения с графическим интерфейсом пользователя, а также через командную строку.",
        liquidity_multiplier: "Мультипликатор ликвидности",
        liquidity_multiplier_description: "Размещайте любое количество лимитных ордеров на одни и те же средства при любом количестве ценовых уровней.",
        multi_swap_support: "Поддержка нескольких обменов",
        multi_swap_support_description: "На одном устройстве одновременно могут выполняться несколько свопов.",
        read_dev_docs: "Прочтите документацию разработчика",
        read_atomic_swaps: "Больше про атомарные свопы",

        compatible_title: "Совместимость с 99% криптоассетов",
        compatible_description: "99 процентов существующих криптоассетов, включая Биткоин, все монеты на основе UTXO, токены Ether и ERC-20, совместимы с AtomicDEX. Мы добавляем поддержку новых ассетов в каждом релизе.",
        featured: "Избранные монеты и токены",
        eth_erc: "ETH и ERC-20",
        qtum_qrc: "QTUM и QRC-20",
        utxo_coins: "UTXO монеты",
        bnb_bep: "BNB и BEP-20",

        swaps_total: "Всего свопов",
        swaps_per_day: "Свопов за последние 24 часа",
        swaps_per_month: "Свопов за последние 30 дней",
        more_stats: "Больше статистики",

        doing_right_title: "Чем отличается AtomicDEX",
        using_p2p_orderbooks: "Децентрализованный P2P ордербук.",
        using_p2p_orderbooks_description: "Многие DEX в блокчейн пространстве сегодня действительно не являются некастодиальными, поскольку средства пользователей, размещенные в пулах ликвидности, постоянно подвергаются временным потерям, атакам и уязвимы для вампирского майнинга. AtomicDEX, напротив, избегает этих проблем, используя децентрализованный P2P ордербук.",
        cross_chain_support: "Самая широкая межпротокольная поддержка",
        cross_chain_support_description: "AtomicDEX не ограничивает торговлю одним блокчейн протоколом. AtomicDEX предлагает самую широкую межпротокольную поддержку во всем блокчейн пространстве. Торговать BTC, ETH, всеми токенами ERC-20, KMD и сотнями других цифровых ассетов напрямую легко - прокси-токены не требуются.",
        secure_as_possible: "Безопасная торговая платформа",
        secure_as_possible_description: "Атомарные свопы разработаны таким образом, что оба трейдера либо получают желаемые средства, либо вообще ничего не происходит, и оба трейдера сохраняют средства.",

        header_title: "Обновите свой DEX опыт",
        header_description: "AtomicDEX - это безопасный кошелек и децентрализованная биржа, не связанная с хранением, в одном приложении.",
        header_download: "Попробуйте AtomicDEX Beta на любой платформе и сделайте свой первый атомарный своп уже сегодня.",

        read_more: "Читать дальше",
        latest_news: "Последние новости",
        news_error: "Ошибка",
        no_news: "Нет новостей",

        lets_try: "Попробовать",
        lets_try_download: "Храните свою криптовалюту и совершайте атомные свопы сегодня.",

        security_title: "Новый уровень безопасности и децентрализации",
        security_subtitle: "Одноранговая торговля, без посредников",
        private_keys: "Ваши ключи, ваши монеты",
        start_trading: "Начните торговать немедленно, не ожидая зачисления депозита и вывода средств",
        trading_pairs: "Неограниченные торговые пары, обменивайте все аccеты напрямую",
        low_fees: "Никаких торговых комиссий для мейкеров, тейкеры платят только 0,13%",

        partner_with_us: "ПРИСОЕДИНЯЙТЕСЬ К СЕТИ DEX",
        partner_with_us_description: "Используйте DeFi 2.0 по максимуму и примените технологию атомарного обмена. Создайте свой собственный DEX, станьте поставщиком ликвидности или разместите свой ассет.",
        join: "Напишите нам",
        your_name: "Ваше имя",
        your_email: "Ваш имейл",
        your_message: "Ваше сообщение",
        project_website: "Вебсайт проекта",
        send: "Отправить",
        resend: "Попробовать снова",
        cancel: "Отменить",
        close: "Закрыть",
        form_error: "Что-то пошло не так",
        form_try_again: "Пожалуйста, попробуйте позднее",
        form_success: "Спасибо! Ваше сообщение было успешно отправлено. Мы свяжемся с вами как можно скорее.",
        click_consent: "Нажимая на кнопку отправки, вы даете согласие на обработку ваших персональных данных.",

        komodo_platform: "Платформа Komodo",
        atomicdex: "AtomicDEX",

        twitter: "Twitter",
        telegram: "Telegram",
        discord: "Discord",

        copyright: "© 2021 Product by",
        design_by: "Design by Weekend Studio",
        language: "Язык",
        english: "English",
        chinese: "体中文",
        korean: "한국어",
        japanese: "日本語",
        russian: "Русский"
    }
}
