import React, { useContext } from "react"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import { Context } from "../Store"

import Language from "./Language"
// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from "headroom.js"

// reactstrap components
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavLink,
  NavItem,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap"

function Navigation(props) {
  const [state, /*dispatch*/] = useContext(Context);
  const { strings } = state

  const [collapseOpen, toggleCollapse] = React.useState(false)
  React.useEffect(() => {
    let headroom = new Headroom(document.getElementById("dark-navbar-main"))
    // initialise
    headroom.init()
  })
  let navbarType = ""
  switch (props.type) {
    case "dark":
      navbarType = "bg-default navbar-dark"
      break
    case "transparent":
      navbarType = "navbar-transparent"
      break
    case "primary":
      navbarType = "bg-primary navbar-dark"
      break
    case "white":
      navbarType = "bg-white"
      break
    default:
      navbarType = "bg-default navbar-dark"
      break
  }

  const scrollToSection = (e, section) => {
    e.preventDefault()
    props.sectionRefs[section].current.scrollIntoView()
  }

  return (
    <>
      <Navbar
        className={"navbar-main headroom " + navbarType}
        expand="lg"
        id="dark-navbar-main"
      >
        <Container >
          <NavbarBrand className="mr-lg-5" to="/" tag={Link}>
            <img alt="AtomicDEX" src={require("assets/img/komodo/" + props.logo)}></img>
          </NavbarBrand>
          <button
            className="navbar-toggler"
            type="button"
            onClick={() => toggleCollapse(!collapseOpen)}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <Collapse
            id="navbar_global"
            navbar
            toggler="#navbar_global"
            isOpen={collapseOpen}
          >
            <div className="navbar-collapse-header">
              <Row>
                <Col className="collapse-brand" xs="6">
                  <Link to="/">
                    <img
                      alt="..."
                      src={require("assets/img/komodo/navbar_logo.svg")}
                    ></img>
                  </Link>
                </Col>
                <Col className="collapse-close" xs="6">
                  <button
                    className="navbar-toggler"
                    onClick={() => toggleCollapse(!collapseOpen)}
                  >
                    <span></span>
                    <span></span>
                  </button>
                </Col>
              </Row>
            </div>
            <Nav
              className="navbar-nav-hover align-items-lg-center ml-lg-auto row-flex"
              navbar
            >
              <NavItem>
                <NavLink onClick={e => scrollToSection(e, "features")}>
                  {strings.features}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink onClick={e => scrollToSection(e, "for_developers")}>
                  {strings.for_developers}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink onClick={e => scrollToSection(e, "assets")}>
                  {strings.assets}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink onClick={e => scrollToSection(e, "news")}>
                  {strings.news}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink onClick={e => scrollToSection(e, "partner")}>
                  {strings.join_us}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink onClick={e => scrollToSection(e, "team")}>
                  {strings.team}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="https://komodoplatform.com/discord" target="_blank">
                  {strings.support}
                </NavLink>
              </NavItem>
              {props.languageSetter &&
                <Language></Language>
              }
            </Nav>

          </Collapse>
        </Container>
      </Navbar>
    </>
  )
}

Navigation.defaultProps = {
  type: "dark",
}

Navigation.propTypes = {
  type: PropTypes.oneOf(["dark", "transparent", "primary", "white"]),
}

export default Navigation
