import React, { useContext, useState } from 'react'
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'

import { Context } from "../Store"

const Language = ({ direction }) => {
  const [state, dispatch] = useContext(Context);
  const { strings } = state

  const [dropdownOpen, setDropdownOpen] = useState(false)

  const toggle = () => setDropdownOpen(prevState => !prevState)

  const changeLanguage = (e, language) => {
    e.preventDefault()
    dispatch({ type: "SET_LANGUAGE", payload: language })
  }
  
  return (
    <UncontrolledDropdown nav inNavbar direction={direction} size="sm">
      <DropdownToggle nav caret>
        {strings.language}
      </DropdownToggle>
      <DropdownMenu>
      { Object.keys(state.localization).map((l, i) => <DropdownItem key={l} onClick={e => changeLanguage(e, l)}>{strings[l]}</DropdownItem>)}
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

export default Language