// reactstrap components
import {
  Card,
  CardImg,
  Col,
  Row,
} from "reactstrap"
import { isMacOs, isMobile, isWindows } from "react-device-detect"

import { FadeIn } from "../Animation"
import React from "react"

function getGithubDownloadLink() {
  // PC
  if (isWindows)
    return "https://github.com/KomodoPlatform/atomicDEX-Desktop/releases/download/0.5.3-beta/atomicdex-desktop-0.5.3-beta-windows-installer.zip"

  if (isMacOs)
    return "https://github.com/KomodoPlatform/atomicDEX-Desktop/releases/download/0.5.3-beta/atomicdex-desktop-0.5.3-beta-osx-dmg.zip"

  return "https://github.com/KomodoPlatform/atomicDEX-Desktop/releases/download/0.5.3-beta/atomicdex-desktop-0.5.3-beta-linux-AppImage.zip"
}

function Download(props) {
  return <Col className={"mx-auto col-xxl-5 " + (props.className || '')} lg="6">
    <FadeIn>
      <h1 className="mr-xl-n-5">{props.title}</h1>
      <p>{props.details}</p>
      <p>{props.call_to_action}</p>
      <Row>
        <Col className="text-center text-lg-left">
          <Card className="download-button">
            <a href="https://testflight.apple.com/join/c2mOLEoC" target="_blank" rel="noopener noreferrer">
              <CardImg
                width="100%"
                alt="..."
                src={require("assets/img/komodo/download_appstore.png")}
              />
            </a>
          </Card>
        </Col>
        <Col className="text-center text-lg-left">
          <Card className="download-button">
            <a href="https://play.google.com/apps/testing/com.komodoplatform.atomicdex" target="_blank" rel="noopener noreferrer">
              <CardImg
                width="100%"
                alt="..."
                src={require("assets/img/komodo/download_googleplay.png")}
              />
            </a>
          </Card>
        </Col>
        {!isMobile &&
          <Col className="text-center text-lg-left">
            <Card className="download-button">
              <a href={getGithubDownloadLink()} target="_blank" rel="noopener noreferrer">
                <CardImg
                  width="100%"
                  alt="..."
                  src={require("assets/img/komodo/download_" +
                    (isWindows ? "windows" : isMacOs ? "mac" : "linux") + ".png")}
                />
              </a>
            </Card>
          </Col>
        }
      </Row>
    </FadeIn>
  </Col>
}

export default Download
