import React from "react"
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";

export function RightSlide({children}) {
  return (
    <ScrollAnimation animateOnce={true} animateIn="fadeInRight">
      {children}
    </ScrollAnimation>
  )
}

export function LeftSlide({children}) {
  return (
    <ScrollAnimation animateOnce={true} animateIn="fadeInLeft">
      {children}
    </ScrollAnimation>
  )
}

export function UpSlide({children}) {
  return (
    <ScrollAnimation animateOnce={true} animateIn="fadeInUp">
      {children}
    </ScrollAnimation>
  )
}

export function FadeIn({children}) {
  return (
    <ScrollAnimation animateOnce={true} animateIn="fadeIn">
      {children}
    </ScrollAnimation>
  )
}